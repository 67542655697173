export var ITEMS_PER_PAGE = 10;
var URL_CDN_SERVER = "https://cdn.alumni-style.ru";
export var STATIC_FILES = {
    leonid: "".concat(URL_CDN_SERVER, "/img/leonid.webp"),
    ivan: "".concat(URL_CDN_SERVER, "/img/ivan.webp"),
    darya: "".concat(URL_CDN_SERVER, "/img/darya.webp"),
    beata: "".concat(URL_CDN_SERVER, "/img/beata.webp"),
    vova: "".concat(URL_CDN_SERVER, "/img/vova.webp"),
    katya: "".concat(URL_CDN_SERVER, "/img/katya.webp"),
    andrew: "".concat(URL_CDN_SERVER, "/img/andrew.webp"),
    ceo: "".concat(URL_CDN_SERVER, "/img/ceo.webp"),
    manager: "".concat(URL_CDN_SERVER, "/img/manager.webp"),
    web: "".concat(URL_CDN_SERVER, "/img/web.webp"),
    mobileManager: "".concat(URL_CDN_SERVER, "/img/MarketingMobileCursor.webp"),
    mobileWeb: "".concat(URL_CDN_SERVER, "/img/BlueWebMobileCursor.webp"),
    background: "".concat(URL_CDN_SERVER, "/img/background.png"),
    blueWebMobileCursor: "".concat(URL_CDN_SERVER, "/img/BlueWebMobileCursor.webp"),
    MarketingMobileCursor: "".concat(URL_CDN_SERVER, "/img/MarketingMobileCursor.webp"),
    greenPic: "".concat(URL_CDN_SERVER, "/img/greenPic.webp"),
    redPic: "".concat(URL_CDN_SERVER, "/img/redPic.webp"),
    bluePic: "".concat(URL_CDN_SERVER, "/img/bluePic.webp"),
    projectDefault: "".concat(URL_CDN_SERVER, "/img/projectDefault.webp"),
    logos: "".concat(URL_CDN_SERVER, "/img/logos.webp"),
    ufcLogo: "".concat(URL_CDN_SERVER, "/icons/ufcLogo.svg"),
    yandexLogo: "".concat(URL_CDN_SERVER, "/icons/yandexLogo.svg"),
    alfaLogo: "".concat(URL_CDN_SERVER, "/icons/alfaLogo.svg"),
    icon01: "".concat(URL_CDN_SERVER, "/icons/01icon.svg"),
    icon02: "".concat(URL_CDN_SERVER, "/icons/02icon.svg"),
    icon03: "".concat(URL_CDN_SERVER, "/icons/03icon.svg"),
    icon04: "".concat(URL_CDN_SERVER, "/icons/04icon.svg"),
    profile: "".concat(URL_CDN_SERVER, "/icons/profile.svg"),
    profiles: "".concat(URL_CDN_SERVER, "/icons/profiles.svg"),
    project: "".concat(URL_CDN_SERVER, "/icons/project.svg"),
    referralPlus: "".concat(URL_CDN_SERVER, "/icons/referralPlus.svg"),
    greenPlus: "".concat(URL_CDN_SERVER, "/icons/greenPlus.svg"),
    logo: "".concat(URL_CDN_SERVER, "/icons/logo.svg"),
    burger: "".concat(URL_CDN_SERVER, "/icons/burger.svg"),
    exit: "".concat(URL_CDN_SERVER, "/icons/exitIcon.svg"),
    telegramSocials: "".concat(URL_CDN_SERVER, "/icons/telegramSocials.svg"),
    telegramIcon: "".concat(URL_CDN_SERVER, "/icons/telegramIcon.svg"),
    vkSocials: "".concat(URL_CDN_SERVER, "/icons/vkSocials.svg"),
    youtubeSocials: "".concat(URL_CDN_SERVER, "/icons/youtubeSocials.svg"),
    checkedCheckbox: "".concat(URL_CDN_SERVER, "/icons/checkedCheckbox.svg"),
    emptyCheckbox: "".concat(URL_CDN_SERVER, "/icons/emptyCheckbox.svg"),
    checkedRadio: "".concat(URL_CDN_SERVER, "/icons/checkedRadio.svg"),
    emptyRadio: "".concat(URL_CDN_SERVER, "/icons/emptyRadio.svg"),
    closeX: "".concat(URL_CDN_SERVER, "/icons/closeX.svg"),
    dots: "".concat(URL_CDN_SERVER, "/icons/dots.svg"),
    edit: "".concat(URL_CDN_SERVER, "/icons/edit.svg"),
    exitIcon: "".concat(URL_CDN_SERVER, "/icons/exitIcon.svg"),
    favicon: "".concat(URL_CDN_SERVER, "/icons/favicon.svg"),
    greenArrow: "".concat(URL_CDN_SERVER, "/icons/greenArrow.svg"),
    greySecArrow: "".concat(URL_CDN_SERVER, "/icons/greySecArrow.svg"),
    blackSecArrow: "".concat(URL_CDN_SERVER, "/icons/blackSecArrow.svg"),
    passwordIcon: "".concat(URL_CDN_SERVER, "/icons/passwordIcon.svg"),
    passwordHide: "".concat(URL_CDN_SERVER, "/icons/passwordHide.svg"),
    search: "".concat(URL_CDN_SERVER, "/icons/search.svg"),
    source: "".concat(URL_CDN_SERVER, "/icons/source.svg"),
    successEmailSend: "".concat(URL_CDN_SERVER, "/icons/successEmailSend.svg"),
    CEOCursor: "".concat(URL_CDN_SERVER, "/LeadsCursors/CEO.png"),
    CPOCursor: "".concat(URL_CDN_SERVER, "/LeadsCursors/CPO.png"),
    CTOCursor: "".concat(URL_CDN_SERVER, "/LeadsCursors/CTO.png"),
    DesignLeadCursor: "".concat(URL_CDN_SERVER, "/LeadsCursors/Design-Lead.png"),
    MobileLeadCursor: "".concat(URL_CDN_SERVER, "/LeadsCursors/Mobile-Lead.png"),
    MarketingLeadCursor: "".concat(URL_CDN_SERVER, "/LeadsCursors/Marketing-Lead.png"),
    SMMLeadCursor: "".concat(URL_CDN_SERVER, "/LeadsCursors/SMM-Lead.png"),
};
export var ROLES_FILTERS = [
    { label: "Участник", value: "participants" },
    { label: "Руководство", value: "leaders" },
];
export var DEPARTMENT_OPTIONS = [
    {
        value: "SMM",
        label: "SMM",
    },
    {
        value: "Marketing",
        label: "Маркетинг",
    },
    {
        value: "Web",
        label: "Web-разработка",
    },
    {
        value: "Design",
        label: "Дизайн",
    },
    {
        value: "Management",
        label: "Менеджмент",
    },
    {
        value: "iOS",
        label: "iOS-разработка",
    },
    {
        value: "Android",
        label: "Android-разработка",
    },
];
export var STATUS_OPTIONS = [
    {
        value: "archive",
        label: "Архив",
    },
    {
        value: "development",
        label: "В разработке",
    },
    {
        value: "production",
        label: "Продакшн",
    },
    {
        value: "release",
        label: "Релиз",
    },
];
export var C_LEVELS = [
    {
        id: 1,
        name: "Леонид Горохов",
        photo: STATIC_FILES.leonid,
        position: "Глава организации",
        tg: "bigchocopoppie",
        role: "CEO",
    },
    {
        id: 2,
        name: "Иван Беседин",
        photo: STATIC_FILES.ivan,
        position: "CTO",
        tg: "go00golo0",
        role: "CTO",
    },
    {
        id: 3,
        name: "Дарья Овсянникова",
        photo: STATIC_FILES.darya,
        position: "Арт-директор",
        tg: "dosvidariaaaa",
        role: "Design-Lead",
    },
    {
        id: 4,
        name: "Беата Белова",
        photo: STATIC_FILES.beata,
        position: "Директор по&nbsp;маркетингу",
        tg: "kipish_manager",
        role: "Marketing-Lead",
    },
    {
        id: 5,
        name: "Владимир Мацнев",
        photo: STATIC_FILES.vova,
        position: "Mobile-Lead",
        tg: "retr0brite",
        role: "Mobile-Lead",
    },
    {
        id: 6,
        name: "Катя Выдренкова",
        photo: STATIC_FILES.katya,
        position: "\u0414\u0438\u0440\u0435\u043A\u0442\u043E\u0440 \u043F\u043E&nbsp;\u043C\u0435\u043D\u0435\u0434\u0436\u043C\u0435\u043D\u0442\u0443",
        tg: "vekaterinavv",
        role: "CPO",
    },
    {
        id: 7,
        name: "Андрей Акимов",
        photo: STATIC_FILES.andrew,
        position: "Lead SMM & PR",
        tg: "andruu6a",
        role: "SMM-Lead",
    },
];
export var LANDING_PROJECTS = [
    {
        title: "Homeet",
        description: "Сервис, который поможет легко найти квартиру или&nbsp;комнату для&nbsp;совместного проживания студентам Вышки",
        link: "https://alumni-style.ru/projects/4",
    },
    {
        title: "Сайт Стилеру",
        description: "Узнайте больше об&nbsp;участниках и&nbsp;проектах организации",
        link: "https://alumni-style.ru/projects/1",
    },
    {
        title: "Termeet",
        description: "Инструмент для совместного планирования встреч, поможет планировать ваше время проще и&nbsp;быстрее",
        link: "https://alumni-style.ru/projects/5",
    },
    {
        title: "SSL",
        description: "Сервис, где можно записаться на занятие в клуб переговоров и&nbsp;узнать информацию о&nbsp;нём и&nbsp;его&nbsp;правилах",
        link: "https://alumni-style.ru/projects/6",
    },
    {
        title: "SMM",
        description: "В наших социальных сетях регулярно приглашаем на&nbsp;лекции и&nbsp;митапы от&nbsp;спикеров с&nbsp;огромным опытом, делимся познавательным контентом и&nbsp;рассказываем о&nbsp;том, что&nbsp;происходит внутри Стилеру",
        link: "https://alumni-style.ru/projects/7",
    },
];
export var METRICS = [
    { start: 5, end: 40, suffix: "+", label: "Активных участников", width: "110" },
    { start: 150, end: 350, suffix: "+", label: "Выпускников Организации", width: "150" },
    { start: 5, end: 100, suffix: "+", label: "Часов практики", width: "130" },
    { start: 5, end: 20, suffix: "k+", label: "Завершённых задач в&nbsp;таск-трекерах", width: "164" },
    { start: 5, end: 100, suffix: "+", label: "Завершённых проектов", width: "130" },
];
